html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:lang(en).text-pl, :lang(pl).text-en {
  display: none;
}

:root {
  --body-color: #eee;
  --paper-color: #fff;
  --font-base-color: #464a4e;
  --font-light-color: #706f6f;
  --font-lighter-color: #cfcfcf;
  --font-lightest-color: #eee;
  font-size: 8px;
}

@media (max-width: 890px) {
  :root {
    font-size: 7px;
  }
}

@keyframes popIn {
  0% {
    opacity: 0;
    transform: scale(1.02);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: var(--body-color);
  color: var(--font-base-color);
  padding: 7rem 1rem;
  font-family: Lato, sans-serif;
  transition: background-color .3s ease-in-out;
}

@media (max-width: 719px) {
  body {
    padding: 7rem 5rem;
  }
}

@media (max-width: 520px) {
  body {
    padding: 0 0 5rem;
  }
}

body.fonts-loading {
  background-color: #fafafa;
  transition: none;
}

body.printable {
  background: none;
  padding: 0;
}

.nav {
  z-index: 1;
  opacity: 1;
  border-bottom: 2px solid #0000;
  justify-content: flex-end;
  gap: 2rem;
  width: 100%;
  max-width: 890px;
  margin: 2rem auto;
  transition: opacity .3s ease-in-out;
  display: flex;
  position: sticky;
  top: 0;
}

@media (max-width: 719px) {
  .nav {
    border-bottom: 1px solid #0000;
  }
}

.nav-button {
  cursor: pointer;
  letter-spacing: .25rem;
  fill: var(--font-light-color);
  color: var(--font-light-color);
  background: none;
  border: none;
  border-radius: 2px;
  align-items: center;
  gap: 1.5rem;
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  transition: all .3s ease-in-out;
  display: flex;
}

.nav-button:hover {
  background: var(--paper-color);
  fill: var(--font-base-color);
  color: var(--font-base-color);
}

.nav-button-caption {
  letter-spacing: .5rem;
  min-width: 4ch;
  font-family: Lato, sans-serif;
  display: inline-block;
}

.nav.sticky {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-bottom: 2px solid var(--body-color);
  background: #fff6;
  padding: 0;
  transition: all .3s ease-in-out;
}

@media (max-width: 719px) {
  .nav.sticky {
    border-bottom: 1px solid var(--body-color);
  }
}

.nav.sticky .nav-button {
  gap: 1rem;
  font-size: 1.15rem;
}

.nav.sticky .nav-button:hover {
  background: none;
}

.printable .nav {
  display: none;
}

.page-container {
  background: var(--paper-color);
  opacity: 1;
  width: 100%;
  max-width: 890px;
  margin: 0 auto;
  padding: 7rem 5rem;
  animation: .3s ease-in-out popIn;
}

@media (max-width: 890px) {
  .page-container {
    padding: 4rem 3rem;
  }
}

@media (max-width: 719px) {
  .page-container {
    padding: 7rem 5rem;
  }
}

@media (max-width: 520px) {
  .page-container {
    padding: 7rem 3rem;
  }
}

@media (max-width: 360px) {
  .page-container {
    padding: 7rem 2rem;
  }
}

.printable .page-container {
  padding: 6rem 5rem;
}

.fonts-loading .page-container {
  opacity: 0;
  animation: none;
}

.fonts-loading .nav {
  opacity: 0;
  transition: none;
}

.header {
  text-transform: uppercase;
  text-align: center;
  border-bottom: 2px solid var(--font-lighter-color);
  justify-content: center;
  gap: 2rem;
  margin-bottom: 5rem;
  padding-bottom: 5rem;
  display: grid;
}

@media (max-width: 719px) {
  .header {
    border-bottom: 1px solid var(--font-lighter-color);
  }
}

.printable .header {
  gap: 1.5rem;
  margin-bottom: 3rem;
  padding-bottom: 3rem;
}

.header h1 {
  letter-spacing: .75rem;
  word-spacing: 1rem;
  font-size: 4rem;
  font-weight: 300;
}

@media (max-width: 420px) {
  .header h1 {
    letter-spacing: .7rem;
    word-spacing: .5rem;
    font-size: 3rem;
  }
}

.header p {
  letter-spacing: .25rem;
  word-spacing: 1rem;
  font-size: 2rem;
  font-weight: 700;
}

@media (max-width: 420px) {
  .header p {
    letter-spacing: .5rem;
    font-size: 1.5rem;
  }
}

.main {
  grid-template-columns: 9fr 5fr;
  gap: 10rem;
  display: grid;
}

@media (max-width: 719px) {
  .main {
    flex-direction: column;
    gap: 0;
    display: flex;
  }
}

.section-left {
  position: relative;
}

.section-left:after {
  content: "";
  background: var(--font-lighter-color);
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  right: -5rem;
}

@media (max-width: 719px) {
  .section-left:after {
    display: none;
  }
}

@media (max-width: 719px) {
  .section {
    display: contents;
  }
}

.block {
  border-bottom: 2px solid var(--font-lighter-color);
  padding: 5rem 0;
}

@media (max-width: 719px) {
  .block {
    border-bottom: none;
    width: 100%;
    max-width: 480px;
    margin: 0 auto;
    padding: 3rem 0;
  }
}

.printable .block {
  padding: 3.5rem 0;
}

.block:first-child {
  padding-top: 0;
}

@media (max-width: 719px) {
  .block:first-child {
    padding-top: 3rem;
  }
}

.block:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

@media (max-width: 719px) {
  .block:last-child {
    padding-bottom: 3rem;
  }
}

.block h2 {
  letter-spacing: .75rem;
  text-transform: uppercase;
  background: var(--font-lightest-color);
  margin-bottom: 3.5rem;
  padding: 1rem 3rem;
  font-size: 2rem;
  font-weight: 300;
  line-height: 3rem;
  display: inline-block;
}

.block-content {
  gap: 1.5rem;
  font-size: 1.75rem;
  line-height: 2.5rem;
  display: grid;
}

.gap-05 {
  gap: .5rem;
  display: grid;
}

.gap-1 {
  gap: 1rem;
  display: grid;
}

.gap-2 {
  gap: 2rem;
  display: grid;
}

.gap-3 {
  gap: 3rem;
  display: grid;
}

.gap-4 {
  gap: 4rem;
  display: grid;
}

.gap-5 {
  gap: 5rem;
  display: grid;
}

h3 {
  text-transform: uppercase;
  letter-spacing: .25rem;
  word-spacing: .5rem;
  font-size: 1.5rem;
  font-weight: 700;
}

@media (max-width: 360px) {
  h3 {
    letter-spacing: .2rem;
    word-spacing: .25rem;
  }
}

b {
  font-weight: 700;
}

i {
  letter-spacing: .05rem;
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 120%;
}

a {
  color: var(--font-base-color);
  text-decoration: none;
}

ul {
  line-height: 2.75rem;
}

li {
  grid-template-columns: auto 1fr;
  margin-bottom: .75rem;
  line-height: 115%;
  display: grid;
}

li:before {
  content: "·";
  padding-right: 1rem;
  font-size: 2rem;
}

.icon-with-text {
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: 2rem;
  display: grid;
}

.icon {
  width: 2.5rem;
  height: 2.5rem;
}

.icon * {
  fill: var(--font-light-color);
  width: 100%;
  height: 100%;
}

.space-between {
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
  display: flex;
}

@media (max-width: 719px) {
  .block-contact {
    order: 1;
  }
}

@media (max-width: 719px) {
  .block-education {
    order: 5;
  }
}

@media (max-width: 719px) {
  .block-experience {
    order: 4;
  }
}

.printable .block-experience .block-content {
  gap: 3rem;
}

.block-projects {
  border-top: 2px solid var(--font-lighter-color);
  border-bottom: none;
  margin-top: 5rem;
  padding-bottom: 0;
}

@media (max-width: 719px) {
  .block-projects {
    border-top: none;
    order: 6;
    margin-top: 0;
  }
}

.printable .block-projects {
  border-top: none;
  margin-top: 5rem;
}

.block-projects .block-content {
  max-width: 85ch;
}

@media (max-width: 719px) {
  .block-skills {
    order: 3;
  }
}

@media (max-width: 719px) {
  .block-summary {
    order: 2;
  }
}

.caption {
  color: var(--font-light-color);
  letter-spacing: .075rem;
  font-size: 1.5rem;
  font-weight: 500;
}

.gdpr {
  text-align: center;
  max-width: 140ch;
  margin: 0 auto;
  padding-top: 7rem;
  display: none;
}

.gdpr i {
  font-size: 1.25rem;
}

.printable .gdpr {
  display: block;
}

/*# sourceMappingURL=index.6a8a8cb8.css.map */
