/* RESET */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: 1;
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

/* TRANSLATIONS */
*:lang('en').text-pl {
    display: none;
}

*:lang('pl').text-en {
    display: none;
}

/* VARIABLES */
:root {
    --body-color: #EEEEEE;
    --paper-color: #FFFFFF;
    --font-base-color: #464A4E;
    --font-light-color: #706F6F;
    --font-lighter-color: #CFCFCF;
    --font-lightest-color: #EEEEEE;
    font-size: 8px;

    @media (max-width: 890px) {
        font-size: 7px;
    }
}

/* ANIMATIONS */
@keyframes popIn {
    0% {
        opacity: 0;
        transform: scale(1.02);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

/* STYLES */
* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: 'Lato', sans-serif;
    background-color: var(--body-color);
    color: var(--font-base-color);
    padding: 7rem 1rem;
    transition: background-color 0.3s ease-in-out;

    @media (max-width: 719px) {
        padding: 7rem 5rem;
    }

    @media (max-width: 520px) {
        padding: 0 0 5rem 0;
    }
}

body.fonts-loading {
    transition: none;
    background-color: #FAFAFA;
}

body.printable {
    background: none;
    padding: 0;
}

.nav {
    max-width: 890px;
    width: 100%;
    margin: 2rem auto;
    display: flex;
    justify-content: flex-end;
    gap: 2rem;
    position: sticky;
    top: 0;
    z-index: 1;
    border-bottom: 2px solid transparent;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;

    @media (max-width: 719px) {
        border-bottom: 1px solid transparent;
    }
}

.nav-button {
    padding: 1rem;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    gap: 1.5rem;
    align-items: center;
    font-size: 1.5rem;
    transition: .3s all ease-in-out;
    border-radius: 2px;
    letter-spacing: .25rem;
    font-weight: 700;
    fill: var(--font-light-color);
    color: var(--font-light-color);
}

.nav-button:hover {
    background: var(--paper-color);
    fill: var(--font-base-color);
    color: var(--font-base-color);
}

.nav-button-caption {
    font-family: 'Lato', sans-serif;
    letter-spacing: .5rem;
    display: inline-block;
    min-width: 4ch;
}

.nav.sticky {
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(10px);
    border-bottom: 2px solid var(--body-color);
    transition: .3s all ease-in-out;
    padding: 0;

    @media (max-width: 719px) {
        border-bottom: 1px solid var(--body-color);
    }
}

.nav.sticky .nav-button {
    gap: 1rem;
    font-size: 1.15rem;
}

.nav.sticky .nav-button:hover {
    background: none;
}

.printable .nav {
    display: none;
}

.page-container {
    max-width: 890px;
    width: 100%;
    margin: 0 auto;
    background: var(--paper-color);
    padding: 7rem 5rem;
    opacity: 1;
    animation: .3s ease-in-out popIn;

    @media (max-width: 890px) {
        padding: 4rem 3rem;
    }

    @media (max-width: 719px) {
        padding: 7rem 5rem;
    }

    @media (max-width: 520px) {
        padding: 7rem 3rem;
    }

    @media (max-width: 360px) {
        padding: 7rem 2rem;
    }
}


.printable .page-container {
    padding: 6rem 5rem;
}

.fonts-loading .page-container {
    animation: none;
    opacity: 0;
}


.fonts-loading .nav {
    opacity: 0;
    transition: none;
}

.header {
    justify-content: center;
    display: grid;
    gap: 2rem;
    padding-bottom: 5rem;
    margin-bottom: 5rem;
    text-transform: uppercase;
    text-align: center;
    border-bottom: 2px solid var(--font-lighter-color);

    @media (max-width: 719px) {
        border-bottom: 1px solid var(--font-lighter-color);
    }
}

.printable .header {
    gap: 1.5rem;
    margin-bottom: 3rem;
    padding-bottom: 3rem;
}

.header h1 {
    font-size: 4rem;
    font-weight: 300;
    letter-spacing: .75rem;
    word-spacing: 1rem;

    @media (max-width: 420px) {
        font-size: 3rem;
        letter-spacing: .7rem;
        word-spacing: .5rem;
    }
}

.header p {
    font-size: 2rem;
    font-weight: 700;
    letter-spacing: .25rem;
    word-spacing: 1rem;

    @media (max-width: 420px) {
        font-size: 1.5rem;
        letter-spacing: .5rem;
    }
}


.main {
    display: grid;
    grid-template-columns: 9fr 5fr;
    gap: 10rem;

    @media (max-width: 719px) {
        display: flex;
        flex-direction: column;
        gap: 0;
    }
}


.section-left {
    position: relative;
}

.section-left::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 2px;
    top: 0;
    right: -5rem;
    background: var(--font-lighter-color);

    @media (max-width: 719px) {
        display: none;
    }
}

.section {
    @media (max-width: 719px) {
        display: contents;
    }
}


.block {
    padding: 5rem 0;
    border-bottom: 2px solid var(--font-lighter-color);

    @media (max-width: 719px) {
        max-width: 480px;
        margin: 0 auto;
        border-bottom: none;
        padding: 3rem 0;
        width: 100%;
    }
}

.printable .block {
    padding: 3.5rem 0;
}

.block:first-child {
    padding-top: 0;

    @media (max-width: 719px) {
        padding-top: 3rem;
    }
}

.block:last-child {
    padding-bottom: 0;
    border-bottom: none;

    @media (max-width: 719px) {
        padding-bottom: 3rem;
    }
}

.block h2 {
    font-size: 2rem;
    line-height: 3rem;
    font-weight: 300;
    letter-spacing: .75rem;
    text-transform: uppercase;
    background: var(--font-lightest-color);
    display: inline-block;
    padding: 1rem 3rem;
    margin-bottom: 3.5rem;
}

.block-content {
    font-size: 1.75rem;
    line-height: 2.5rem;
    display: grid;
    gap: 1.5rem;
}

.gap-05 {
    display: grid;
    gap: .5rem;
}

.gap-1 {
    display: grid;
    gap: 1rem;
}

.gap-2 {
    display: grid;
    gap: 2rem;
}

.gap-3 {
    display: grid;
    gap: 3rem;
}

.gap-4 {
    display: grid;
    gap: 4rem;
}

.gap-5 {
    display: grid;
    gap: 5rem;
}

h3 {
    font-size: 1.5rem;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: .25rem;
    word-spacing: .5rem;

    @media (max-width: 360px) {
        letter-spacing: .2rem;
        word-spacing: .25rem;
    }
}

b {
    font-weight: 700;
}

i {
    font-size: 1.5rem;
    letter-spacing: .05rem;
    font-weight: 300;
    line-height: 120%;
}

a {
    color: var(--font-base-color);
    text-decoration: none;
}

ul {
    /*list-style: outside;*/
    line-height: 2.75rem;
}

li {
    display: grid;
    line-height: 115%;
    margin-bottom: .75rem;
    grid-template-columns: auto 1fr;
}

li:before {
    content: "·";
    font-size: 2rem;
    padding-right: 1rem;
}


.icon-with-text {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 2rem;
    align-items: center;
}

.icon {
    height: 2.5rem;
    width: 2.5rem;
}

.icon * {
    height: 100%;
    width: 100%;
    fill: var(--font-light-color);
}

.space-between {
    display: flex;
    gap: 2rem;
    justify-content: space-between;
    width: 100%;
}

.block-contact {
    @media (max-width: 719px) {
        order: 1;
    }
}

.block-education {
    @media (max-width: 719px) {
        order: 5;
    }
}

.block-experience {
    @media (max-width: 719px) {
        order: 4;
    }
}

.printable .block-experience .block-content {
    gap: 3rem;
}

.block-projects {
    border-top: 2px solid var(--font-lighter-color);
    border-bottom: none;
    margin-top: 5rem;
    padding-bottom: 0;
    @media (max-width: 719px) {
        border-top: none;
        margin-top: 0;
        order: 6;
    }
}

.printable .block-projects {
    margin-top: 5rem;
    border-top: none;
}

.block-projects .block-content {
    max-width: 85ch;
}

.block-skills {
    @media (max-width: 719px) {
        order: 3;
    }
}

.block-summary {
    @media (max-width: 719px) {
        order: 2;
    }
}

.caption {
    color: var(--font-light-color);
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: .075rem;
}

.gdpr {
    display: none;
    padding-top: 7rem;
    max-width: 140ch;
    text-align: center;
    margin: 0 auto;
}

.gdpr i {
    font-size: 1.25rem;
}

.printable .gdpr {
    display: block;
}